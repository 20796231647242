import React, {useEffect, useState} from 'react';
import Like from '../../../../assets/images/like.png';
import Dislike from '../../../../assets/images/dislike.png';
import CopyGray from '../../../../assets/images/copy-gray.png';

import {classNames} from '@commonFunction';
import _ from 'lodash';
import {toast} from 'react-hot-toast';
import AnimatedSVG from './animatedsvg';
import {getContact} from '../actions';
import {connect} from 'react-redux';
import {
  appConstant,
  normalizeLinkedinUrl,
  parse,
} from '../../../../common/common-utils';

const noEmail = 'No email found';
const IconImageSize = 16;

function Personal(props) {
  const [selectedEmail, setSelectedEmail] = useState('');
  const [showEmailTooltip, setShowEmailTooltip] = useState(false);
  const [fetchPersonal, setFetchPersonal] = useState(false);

  const fetchContact = type => {
    appConstant.kFetchedPersonalEmail = false;
    props.getContact(
      props.session,
      {
        linkedinurl: normalizeLinkedinUrl(props.linkedinurl),
        type,
        action: 'reveal',
      },
      response => {
        setFetchPersonal(false);
      },
    );
  };

  return (
    <div
      className={classNames(
        'flex flex-row justify-between',
        props.islast ? '' : 'border-b border-solid border-gray-300',
      )}>
      {props.personalEmails.length && props.showPersonal ? (
        <div className={classNames('mx-5 flex flex-col py-6')}>
          <span className="text-lg font-medium text-gray-700">
            {'Personal Email'}
          </span>
          {_.map(props.personalEmails, (item, idx) => {
            return (
              <div
                onMouseEnter={() => {
                  if (item.address !== noEmail) {
                    setSelectedEmail(item.address);
                    setShowEmailTooltip(true);
                  }
                }}
                onMouseLeave={() => {
                  setSelectedEmail('');
                  setShowEmailTooltip(false);
                }}
                key={idx}
                className="flex flex-row items-center justify-between relative cursor-pointer ">
                <span className="text-xl text-gray-500">{item.address}</span>
                {props.personal_email_encrypted ? null : showEmailTooltip &&
                  selectedEmail === item.address ? (
                  <div className="cursor-pointer shadow absolute right-0 flex flex-row border-gray-200 rounded-lg border border-solid px-4 py-3 back bg-white">
                    <span
                      onClick={() => {
                        props.copy(item.address);
                        toast.success('Successfully copied!');
                      }}>
                      <img className="h-4 mr-6" src={CopyGray}></img>
                    </span>
                    <span
                      onClick={() => {
                        props.updateContactMeta(
                          item.address,
                          'upvote',
                          'personalemail',
                        );
                      }}>
                      <img className="h-4 mr-6" src={Like}></img>
                    </span>
                    <span
                      onClick={() => {
                        props.updateContactMeta(
                          item.address,
                          'downvote',
                          'personalemail',
                        );
                      }}>
                      <img className="h-4" src={Dislike}></img>
                    </span>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}

      {props.personalEmails.length &&
      props.showPersonal &&
      props.personal_email_encrypted &&
      !props.expired &&
      !props.nodetails &&
      !appConstant.kFetchedPersonalEmail ? (
        <div className="w-45 right items-center justify-center flex flex-col">
          <div
            className={classNames(
              'mx-2 bg-themeblue justify-center items-center rounded-lg cursor-pointer relative',
              fetchPersonal ? 'opacity-70 w-44' : 'opacity-100',
            )}
            onClick={() => {
              if (props.personalcredits > 0) {
                setFetchPersonal(true);
                fetchContact('personalemail');
              } else {
                props.history.push('/dashboard/upgradeplan');
              }
            }}>
            <div className="btn-primary">
              {fetchPersonal
                ? 'Please wait'
                : props.personalcredits > 0
                  ? 'Reveal Email*'
                  : 'Upgrade'}
            </div>
            {fetchPersonal ? (
              <div className="absolute right-2 top-2.5">
                <AnimatedSVG />
              </div>
            ) : null}
          </div>
          <div className="flex flex-row items-center">
            <span className="text-xs text-gray-400 mt-2">
              {'*1 credit will be deducted'}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  const {searchedData, appuserData, session} = state;

  let noPersonalEmails = true;
  let personalEmails = [];
  let encrypted = false;
  if (searchedData) {
    let {personal_email_encrypted, personalemailcost} = searchedData;
    let emails = parse(searchedData.emails, session.sessionToken);

    emails =
      emails && emails !== 'null' ? JSON.parse(emails.replace(/'/g, '"')) : [];

    personalEmails = emails.filter(email => email.type === 'personal');

    if (personalEmails.length === 0) {
      if (appConstant.kFetchedPersonalEmail) {
        personalEmails.push({
          address: noEmail,
          type: 'none',
        });
      } else {
        personalEmails.push({
          address: '*****@****.***',
          type: 'none',
        });
      }
    } else {
      noPersonalEmails = false;
    }

    encrypted = personal_email_encrypted;
  }

  return {
    searchedData,
    personalcredits: appuserData.credits.personalcredits,
    session,
    noPersonalEmails,
    personalEmails,
    personal_email_encrypted: encrypted,
  };
}

export default connect(mapStateToProps, {getContact})(Personal);
