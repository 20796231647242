import React from 'react';
import _ from 'lodash';
import NoContact from './no-contact';

import Personal from './personal';
import Business from './business';
import Phone from './phone';

import {connect} from 'react-redux';
import {parse} from '../../../../common/common-utils';

function ContactCredits(props) {
  let name = props.name;

  const copy = value => {
    navigator.clipboard.writeText(value);
  };

  const {showPersonal, showBusiness, showPhone} = props;

  return (
    <div className="flex flex-col px-2 pb-4">
      <div className="flex flex-row border-b border-solid border-gray-300 py-4">
        <div className="overflow-hidden">
          <div className="flex flex-row items-center">
            <img
              className="w-12 h-12 mx-3 rounded-full"
              src={`https://ui-avatars.com/api/?name=${name}&background=4470FB&color=fff`}></img>
            <div className="ml-0 flex flex-col">
              <span className="text-xl font-bold font-inter text-gray-700">
                {name.toUpperCase()}
              </span>
            </div>
          </div>
        </div>
      </div>

      {props.nodetails ? (
        <NoContact validProfileUrl={true} />
      ) : (
        <div className="flex-row">
          <Personal
            {...props}
            updateContactMeta={props.updateContactMeta}
            copy={copy}
            islast={
              (showPersonal && !showBusiness && !showPhone) ||
              (!showPersonal && !showBusiness && !showPhone)
            }
          />
          <Business
            {...props}
            updateContactMeta={props.updateContactMeta}
            copy={copy}
            islast={
              (showPersonal && showBusiness && !showPhone) ||
              (!showPersonal && !showBusiness && !showPhone)
            }
          />

          <Phone
            {...props}
            updateContactMeta={props.updateContactMeta}
            copy={copy}
            islast={
              (showPersonal && showBusiness && showPhone) ||
              (!showPersonal && !showBusiness && !showPhone)
            }
          />

          {/* {props.showInfo ? (
            <div className="flex flex-row items-center justify-start ml-4">
              <span className="text-sm font-medium text-gray-500 mt-2">
                {'*1 credit will be deducted'}
              </span>
            </div>
          ) : null} */}

          {(props.credits.personalcredits === 0 ||
            props.credits.businesscredits === 0) &&
          props.credits.contactcredits === 0
            ? {
                /*<motion.div
        className="mx-7 my-12 border border-theme20 border-solid flex flex-col bg-gray-50 rounded-lg px-10 py-5"
        initial={{opacity: 0, scale: 0}}
        animate={{opacity: 1, scale: 1}}
        transition={{delay: 0.2}}>
        <div
          className="flex flex-row items-center mt-0 cursor-pointer"
          onClick={() => {
            if (subscriptionSKU == 'custom_trial')
              window.open(
                `${appConstant.kWebUrl}#/dashboard/upgradeplan?session_token=${props.sessionToken}`,
                '_blank',
              );
            else
              window.open(
                `${appConstant.kWebUrl}#/dashboard/creditaddons?session_token=${props.sessionToken}`,
                '_blank',
              );
          }}>
          <span className="text-2xl font-medium font-inter text-themeblue">
            {subscriptionSKU == 'custom_trial'
              ? 'Upgrade Your Plan'
              : 'Buy Addon Credits'}
          </span>
          <img className="h-6 ml-5" src={Next}></img>
        </div>
        </motion.div>*/
              }
            : null}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const {searchedData, appuserData, session} = state;
  let nodetails = true;
  let name = '';
  let showInfo = false;
  if (searchedData) {
    const {full_name} = searchedData;
    const phone_numbers = parse(
      searchedData.phone_numbers,
      session.sessionToken,
    );
    const emails = parse(searchedData.emails, session.sessionToken);
    const phonenumbers =
      phone_numbers && phone_numbers !== 'null'
        ? JSON.parse(phone_numbers.replace(/'/g, '"'))
        : [];
    const allemails =
      emails && emails !== 'null' ? JSON.parse(emails.replace(/'/g, '"')) : [];

    nodetails = allemails.length === 0 && phonenumbers.length === 0;
    name = full_name;

    const {
      phone_encrypted,
      business_email_encrypted,
      personal_email_encrypted,
    } = searchedData;
    showInfo =
      phone_encrypted || business_email_encrypted || personal_email_encrypted;

    if (showInfo) {
      showInfo = !nodetails;
    }
  }

  return {
    searchedData,
    nodetails,
    credits: appuserData.credits,
    name,
    showInfo,
  };
}

export default connect(mapStateToProps, null)(ContactCredits);
