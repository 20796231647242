import React, {useEffect, useState} from 'react';
import Like from '../../../../assets/images/like.png';
import Dislike from '../../../../assets/images/dislike.png';
import CopyGray from '../../../../assets/images/copy-gray.png';

import {classNames} from '@commonFunction';
import _ from 'lodash';
import {toast} from 'react-hot-toast';
import AnimatedSVG from './animatedsvg';
import {getContact} from '../actions';
import {connect} from 'react-redux';
import {
  appConstant,
  normalizeLinkedinUrl,
  parse,
} from '../../../../common/common-utils';

const noContact = 'No phone number found';

function Phone(props) {
  const [selectedEmail, setSelectedEmail] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [fetchPhone, setFetchPhone] = useState(false);

  const fetchContact = type => {
    appConstant.kFetchedPhone = false;
    props.getContact(
      props.session,
      {
        linkedinurl: normalizeLinkedinUrl(props.linkedinurl),
        type,
        action: 'reveal',
      },
      response => {
        setFetchPhone(true);
      },
    );
  };

  return (
    <div
      className={classNames(
        'flex flex-row justify-between',
        props.islast ? '' : 'border-b border-solid border-gray-300',
      )}>
      {props.phonenumbers.length && props.showPhone ? (
        <div className={classNames('mx-5 flex flex-col py-6')}>
          <span className="text-lg font-medium text-gray-700">
            {'Direct Dial'}
          </span>
          {_.map(props.phonenumbers, (item, idx) => {
            return (
              <div
                onMouseEnter={() => {
                  if (item !== noContact) {
                    setSelectedEmail(item);
                    setShowTooltip(true);
                  }
                }}
                onMouseLeave={() => {
                  setSelectedEmail('');
                  setShowTooltip(false);
                }}
                key={idx}
                className="flex flex-row items-center justify-betweencursor-pointer relative">
                <span className="text-xl font-normal text-gray-500">
                  {item}
                </span>
                {props.phone_encrypted ? null : showTooltip &&
                  selectedEmail === item ? (
                  <div className="cursor-pointer absolute right-0 flex flex-row border-gray-200 rounded-lg border border-solid px-4 py-3 bg-white">
                    <span
                      onClick={() => {
                        props.copy(item);
                        toast.success('Successfully copied!');
                      }}>
                      <img className="h-4 mr-6" src={CopyGray}></img>
                    </span>
                    <span
                      onClick={() => {
                        props.updateContactMeta(item, 'upvote', 'phone');
                      }}>
                      <img className="h-4 mr-6" src={Like}></img>
                    </span>
                    <span
                      onClick={() => {
                        props.updateContactMeta(item, 'downvote', 'phone');
                      }}>
                      <img className="h-4" src={Dislike}></img>
                    </span>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
      {props.phonenumbers.length &&
      props.showPhone &&
      props.phone_encrypted &&
      !props.expired &&
      !props.nodetails &&
      !appConstant.kFetchedPhone ? (
        <div className="w-45 right items-center justify-center flex flex-col">
          <div
            className={classNames(
              'mx-2 bg-themeblue justify-center items-center rounded-lg cursor-pointer relative min-w-30',
              fetchPhone ? 'opacity-70 w-44' : 'opacity-100',
            )}
            onClick={() => {
              if (!fetchPhone) {
                if (props.dialcredits === 0) {
                  if (props.subscriptionSKU == 'custom_trial')
                    props.history.push('/dashboard/upgradeplan');
                  else props.history.push('/dashboard/creditaddons');
                } else {
                  setFetchPhone(true);
                  fetchContact('directdial');
                }
              }
            }}>
            <div className="btn-primary">
              {fetchPhone
                ? 'Please wait'
                : props.dialcredits > 0
                  ? 'Get Phone Number*'
                  : 'Buy Direct Dial Credits'}
            </div>
            {fetchPhone ? (
              <div className="absolute right-2 top-2.5">
                <AnimatedSVG />
              </div>
            ) : null}
          </div>
          <div className="flex flex-row items-center">
            <span className="text-xs text-gray-400 mt-2">
              {'*1 credit will be deducted'}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const {searchedData, appuserData, session} = state;

  let noPhoneNumbers = true;
  let phonenumbers = [];
  let encrypted = false;
  if (searchedData) {
    let {phone_encrypted, phonecost} = searchedData;
    let phone_numbers = parse(searchedData.phone_numbers, session.sessionToken);
    phonenumbers =
      phone_numbers && phone_numbers !== 'null'
        ? JSON.parse(phone_numbers.replace(/'/g, '"'))
        : [];

    if (phonenumbers.length === 0) {
      if (appConstant.kFetchedPhone) {
        phonenumbers.push(noContact);
      } else {
        phonenumbers.push('+**********');
      }
    } else {
      noPhoneNumbers = false;
    }

    encrypted = phone_encrypted;
  }

  return {
    searchedData,
    dialcredits: appuserData.credits.contactcredits,
    session,
    noPhoneNumbers,
    phonenumbers,
    phone_encrypted: encrypted,
  };
}

export default connect(mapStateToProps, {getContact})(Phone);
